<template>
  <b-button
    :variant="mode === 'primary' ? 'outline-primary' : 'outline-light'"
    size="md"
    class="change-language-btn d-flex align-items-center"
    :class="mode"
    @click="$store.commit('env/setIsChangeLanguageModalShow', true)"
  >
    <i class="icon-world text-24"></i>
    <span class="ml-2 label">{{ getText() }} </span>
  </b-button>
</template>

<script>
import languages from '@/json/languages.json';
export default {
  props: {
    mode: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      languages,
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.state.env.currentLanguage;
    },
  },
  methods: {
    getText() {
      const current = languages.find(
        (language) => language.value === this.currentLanguage
      );
      return current.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.change-language-btn {
  border-radius: 12px;
  padding: 3px 12px;
  transition: all 0.3s;
  &.primary {
    border: 1px solid $font-grayscale-2;
    .label {
      color: $font-grayscale-2;
    }
    &:hover {
      border-color: transparent;
      .label {
        color: $white;
      }
    }
  }
}
</style>
