<template>
  <b-navbar
    class="login-header bg-white d-flex justify-content-between w-100 p-0 pr-md-5 pl-md-4"
  >
    <b-navbar-brand
      class="cursor-pointer p-0 h-100"
      @click="$router.push('intro')"
    >
      <img
        class="logo-orange img-fluid"
        :srcset="require('@/assets/img/logo-orange-transparent.png?srcset')"
      />
    </b-navbar-brand>

    <b-navbar-nav>
      <slot></slot>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.login-header {
  .nav-item {
    margin-right: 8px;
    a {
      padding: 6px;
    }
    &:last-child {
      margin-right: 6px;
    }
  }
  .logo-orange {
    height: 56px;
    @media screen and (min-width: 768px) {
      height: 68px;
    }
  }
}
</style>
