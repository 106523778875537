<template>
  <div class="guest-login-detail bg-bgsection d-flex flex-column">
    <LoginHeader> </LoginHeader>

    <div class="guest-login-detail-wrapper scroll-bar">
      <div
        class="guest-login-detail-container d-flex align-items-center justify-content-center flex-column mx-auto px-3 px-md-0"
      >
        <h3 class="mb-4 mb-md-5">
          {{ $t('開始前我們建議您...') }}
        </h3>
        <p class="sub-title text-center text-18 mb-4 mb-md-5">
          {{ $t('點擊下方【下載截圖】按鈕') }}
          <br />
          {{ $t('保存您的帳號及密碼。') }}
        </p>

        <div
          ref="account"
          class="account-detail font-weight-bold text-18 bg-white p-3 text-center mb-4 mb-md-5"
        >
          <p class="text-primary mb-1">{{ $t('訪客 ID') }}</p>
          <p class="mb-0">{{ guestDetail?.username }}</p>
          <hr class="divider my-3" />
          <p class="text-primary mb-1">{{ $t('密碼') }}</p>
          <p class="mb-0">{{ guestDetail?.password }}</p>
        </div>
        <b-button
          variant="white"
          size="lg"
          class="w-100 font-weight-bold mb-4 mb-md-5"
          @click="downloadScreenShot"
        >
          {{ $t('下載截圖') }}
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          class="w-100 font-weight-bold"
          @click="onClickGoHome"
        >
          {{ $t('略過此步驟') }}
        </b-button>
      </div>
    </div>

    <CopyrightFooter mode="primary" />
  </div>
</template>

<script>
import CopyrightFooter from '@/components/Base/CopyrightFooter.vue';
import LoginHeader from '@/components/Base/LoginHeader.vue';
import html2canvas from 'html2canvas';
import savePhoto from '@/lib/base/savePhoto.js';

export default {
  components: {LoginHeader, CopyrightFooter},
  data() {
    return {};
  },
  computed: {
    guestDetail() {
      return this.$store.state.user.guestDetail;
    },
    isLogin() {
      return this.$store.getters['user/isLogin'];
    },
  },
  created() {
    // if (this.guestDetail == null) {
    //   this.$router.push({name: this.isLogin ? 'home' : 'signup'});
    // }
  },
  beforeDestroy() {
    this.$store.commit('user/setGuestDetail', null);
  },
  methods: {
    onClickGoHome() {
      this.$router.push({name: 'home'});
    },
    async covert2Base64(dom) {
      const canvas = await html2canvas(dom, {backgroundColor: null});
      const base64 = canvas.toDataURL();
      return base64;
    },
    async downloadScreenShot() {
      this.$store.commit('env/setIsLoading', true);
      try {
        const element = this.$refs.account;
        const base64 = await this.covert2Base64(element);
        savePhoto(
          base64,
          `jungo-guest-account-${this.guestDetail.username}.png`
        );
        this.$Message.success(this.$t('下載成功'), 3000);
      } catch (error) {
        this.$Message.error(this.$t('下載失敗'), 3000);
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.guest-login-detail-wrapper {
  overflow: overlay;
  height: calc(
    100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 56px - 48px
  );
  @media screen and (min-width: 768px) {
    height: calc(
      100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 68px -
        48px
    );
  }
}
.guest-login-detail-container {
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;

  @media screen and (min-width: 768px) {
    height: min-content;
    min-height: -webkit-fill-available;
    max-width: 370px;
  }
  h3 {
    font-size: 28px;
    line-height: 44px;
  }
  .sub-title {
    color: $font-grayscale-1;
    line-height: 30px;
  }
  .account-detail {
    border-radius: 24px;
    width: 288px;
    line-height: 30px;
  }

  button {
    border-color: $primary;
  }
}
</style>
