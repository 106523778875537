<template>
  <div
    class="copyright-footer d-flex justify-content-center justify-content-md-between align-items-center text-font-grayscale-2 w-100"
    :class="[mode === 'secondary' ? 'bg-secondary' : 'bg-white', mode]"
  >
    <div class="d-none d-md-block">
      <a
        :href="manifest.web"
        class="underline"
        :title="$t('純棋官方網站')"
        @click.prevent="onClickWeb"
      >
        {{ $t('純棋官方網站') }}
      </a>
      <router-link :to="{name: 'terms-of-service'}" class="mx-3 underline">
        {{ $t('服務條款') }}
      </router-link>
      <router-link :to="{name: 'privacy-policy'}" class="underline">
        {{ $t('隱私政策') }}
      </router-link>
    </div>

    <p class="rights mr-3 mr-md-0 m-0">@2023 Jungo. All Rights Reserved.</p>
    <ChangeLanguageBtn :mode="mode"></ChangeLanguageBtn>
  </div>
</template>

<script>
import {Browser} from '@capacitor/browser';
import {manifest} from '@/constant/env.js';
import ChangeLanguageBtn from '@/components/Base/ChangeLanguageBtn.vue';
export default {
  components: {ChangeLanguageBtn},
  props: {
    mode: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {manifest};
  },
  methods: {
    onClickWeb() {
      Browser.open({
        url: 'http://jungo.go-en.com/',
        presentationStyle: 'popover',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.copyright-footer {
  color: $font-grayscale-2;
  padding: 8px 0;
  a {
    color: $font-grayscale-2;
  }
  @media screen and (min-width: 768px) {
    padding: 8px #{'min(240px, 13%)'};
  }

  &.secondary {
    color: $white;

    a {
      color: $white;
    }
  }
  .rights {
    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
}
</style>
